import React from "react";
import Footer from "../components/Footer";
import Header from "../components/header";
import HeroBackground from "../components/HeroBackground";
import SEO from "../components/seo";

function NotFound({}) {
  return (
    <React.Fragment>
      <SEO
        title={"404"}
        description={"Pagina niet gevonden"}
        pathname={"/404"}
      />

      <div className="flex flex-col font-sans min-h-screen text-gray-900">
        <Header />
        <HeroBackground>
          <section className="w-full  mt-32  flex flex-col flex-1 md:justify-center">
            <section className="text-white flex flex-col justify-center content-center items-center max-w-5xl  container mx-auto  text-center my-8 h-full md:h-40">
              <h1 className=" mt-2  text-4xl leading-tight xl:text-5xl font-semibold font-display text-white">
                De pagina kon niet worden gevonden.
              </h1>
            </section>
          </section>
        </HeroBackground>
        <main className="container mx-auto px-6 py-10  max-w-7xl "></main>
        <Footer isDark={true} />
      </div>
    </React.Fragment>
  );
}
export default NotFound;
